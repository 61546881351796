<template>
    <div v-show="message">
        <p class="text-sm text-red-400">
            <template v-if="typeof message == 'object'">{{ message.join("\n") }}</template>
            <template v-else>{{ message }}</template>
        </p>
    </div>
</template>

<script>
    import { defineComponent } from 'vue'

    export default defineComponent({
        props: ['message']
    })
</script>
