<template>
  <header id="header" class="section py-4 lg:py-10 px-4 mb-6 lg:mb-0 bg-customRed text-white" role="banner">
    
        <div class="container mx-auto ">
            
            <div class="text-base lg:text-lg flex flex-wrap overflow-hidden items-center relative">

                <div class="absolute lg:static lg:w-1/3 overflow-hidden order-last lg:order-first mx-auto mt-2 lg:mt-5 lg:mt-0 mb-10 lg:mb-0">

                </div>

                <div class="w-full lg:w-1/3 overflow-hidden text-center">
                    <a href="/"><app-logo class="h-auto w-56 mx-auto" /></a>
                </div>

                <div class="w-full lg:w-1/3 overflow-hidden text-center lg:text-right mt-5 lg:mt-2 font-semibold lg:text-base text-xs">
                    CALL US <a href="tel:0299995967">(02) 9999 5967</a>
                </div>

            </div>

            
        </div>
  </header>
</template>
<script>
import AppLogo from './AppLogo';
export default {
    name: 'Header',
    components: {
        AppLogo,
    },
    methods: {
       
    },
    computed: {
       
    }
}
</script>