<template>
  <div class="min-h-screen bg-center md:bg-cover flex justify-between flex-col overflow-x-hidden">
    <div class="absolute top-0 opacity-50 right-0 z-10 w-full text-right py-1 text-xs">
      <span v-if="isOffline" class="inline-flex items-center text-xs font-medium mr-2">
          <span class="w-2 h-2 mr-1 bg-red-500 rounded-full" title="App is running offline"></span>
          <!-- Offline -->
      </span>
      <span v-else class="inline-flex items-center text-green-800 text-xs font-medium mr-2">
          <span class="w-2 h-2 mr-1 bg-green-500 rounded-full" title="App is online"></span>
          <!-- Online -->
      </span>
    </div>    
    <Header></Header>
    
    <!-- start -->
    <div class="flex justify-center pb-10 mt-3 px-4 md:px-0 form-entry relative" v-if="loaded">
        <div class="absolute text-xs top-0 left-2 opacity-10" v-if="draw.total_population && debug">
          <div>
            <jet-label value="DETAILS (To be removed)" />
            <jet-label value="Total Population" class="mt-2" />
            <div>{{ draw.total_population }}</div>
          </div>
          <div class="mt-2">
            <jet-label value="Tests Required" />
            <div>{{ draw.tests_required }}</div>
          </div>
          
          <div class="mt-2">
            <jet-label value="Winners" />
            <ul class="grid grid-cols-4">
              <template v-for="(item, index) in draw.results">
                <li v-if="item == true">#{{ String(index + 1).padStart(4, '0') }}</li>
              </template>
            </ul>
          </div>
          

        </div>
        <transition-group  name="fade" :key="1">
          
            <div class="w-full form-entry relative  md:min-h-200 mx-auto" :key="'child_2'" v-if="draw && draw.total_population > 0">
              <!-- <pre class="text-xs">{{ draw }}</pre> -->
              <form class="max-w-md mx-auto flex flex-wrap justify-between items-start" style="min-height:73vh" v-if="!allWinnersDrawed">
                <div class="mx-auto text-center w-full">
                    <h2 class="text-lg">TEST #{{ String(draw.current_index + 1).padStart(4, '0') }}</h2>
                </div>

                <transition-group  name="fade" :key="'draw-button'">
                  <div v-if="draw.current_index_result_shown" class="text-8xl font-bold text-center mx-auto w-56 h-56 flex items-center justify-center my-0">
                    <template v-if="draw.results[draw.current_index] == true">YES</template>
                    <template v-else>NO</template>
                  </div>
                  <div v-else class="text-center w-full">
                    <button type="button" @click.prevent="pressButtonDraw" class="w-56 h-56 rounded-full 
                        bg-blue-600 hover:opacity-50 text-white uppercase text-2xl px-16 text-center my-10">
                        Press the button
                    </button>
                  </div>
                </transition-group>

                <div class="actions w-full">
                  <jet-button v-if="draw.current_index_result_shown" type="button" @click.prevent="pressButtonNext" class="mb-6" buttonType="secondary">Next</jet-button>
                  <jet-button type="button" @click.prevent="cancelDraw" class="my-1" buttonType="primary">Finish</jet-button>
                </div>
              </form>
              <div v-else>
                <div class="mx-auto max-w-md">
                    <h2 class="text-center">Random Testing Complete!</h2>

                    <div class="mt-10">
                      <h4>Random Testing Information</h4>
                      <div>
                        <jet-label value="Total Population" class="mt-2" />
                        <div>{{ draw.total_population }}</div>
                      </div>
                      <div class="mt-2">
                        <jet-label value="Tests Required" />
                        <div>{{ draw.tests_required }}</div>
                      </div>
                      <div class="mt-2">
                        <jet-label value="Tests" />
                        <ul class="grid grid-cols-5">
                          <template v-for="(item, index) in draw.results">
                            <li v-if="item == true">
                              #{{ String(index + 1).padStart(4, '0') }}
                            </li>
                          </template>
                        </ul>
                      </div>



                        <div class="rounded-md bg-blue-50 p-4 mt-6 opacity-75" v-if="draw.current_index+1 <= draw.total_population">
                          <div class="flex">
                            <div class="flex-1 md:flex md:justify-between">
                              <p class="text-sm text-blue-700">Test complete as all of the requirements have been met.</p>
                            </div>
                          </div>
                        </div>

                      </div>
                    

                    <div class="actions w-full mt-20">
                      <jet-button type="button" @click.prevent="cancelDraw" class="my-1" buttonType="primary">Finish</jet-button>
                    </div>
                </div>
              </div>
            </div>
            <div class="w-full form-entry pb-10 relative min-h-auto md:min-h-200 mx-auto" :key="'child_1'" v-else>
              <form @submit.prevent="submitDraw">
                <div class="mx-auto text-center max-w-4xl">
                    <h2>Setup Random Testing</h2>
                </div>

                <div class="mb-3 actions mx-auto max-w-md grid grid-cols-3 gap-8 mt-10" >
                    <div class="col-span-12">
                        <jet-label :value="'Total population'" class="required" />
                        <jet-input type="number" v-model="form.total_population" class="mt-1 block w-full mb-2 " placeholder="Enter total population" />
                        <jet-input-error :class="{'hidden':form.processing}" :message="form.errors.errors.total_population" class="mt-2" />

                        <!-- <pre>{{ form.errors.errors.total_population.join("\n") }}</pre> -->
                    </div>
                </div>


                <div class="overflow-hidden justify-center mt-4 actions items-start mx-auto max-w-md">
                  <jet-label value="Tests Required" class="required" />
                    <label v-for="(option, option_index) in tests_required_options" :key="option_index" class="flex justify-start items-center my-3">
                        
                        <input type="radio" :value="option.key" name="tests_required_type" v-model="form.tests_required_type" class="mr-2 ml-1"  />
                        <div class="input-radio text-sm">{{ option.label }}</div>
                        <!-- <pre>{{  option }}</pre> -->
                    </label>
                    <jet-input-error :class="{'hidden':form.processing}" :message="form.errors.errors.tests_required_type" class="mt-2" />
                </div>

                <div class="mb-3 actions mx-auto max-w-md mt-4" >
                    <div class="col-span-12">

                        <jet-input type="number" v-model="form.tests_required_exact" class="mt-1 block w-full mb-2 " v-if="form.tests_required_type == 'exact'" placeholder="Enter exact number of tests required" />
                        <jet-input type="number" v-model="form.tests_required_percentage" class="mt-1 block w-full mb-2 " v-else-if="form.tests_required_type == 'percentage'" placeholder="Enter percentage of tests required" />

                        <div class="text-sm" v-if="form.tests_required_type == 'percentage' && form.total_population && form.tests_required_percentage" >{{ totalFromPercentage }} subjects will be randomly tested from {{ form.total_population }} population.</div>
                        <jet-input-error :class="{'hidden':form.processing}" :message="form.errors.errors.tests_required_percentage" class="mt-2" />
                        <jet-input-error :class="{'hidden':form.processing}" :message="form.errors.errors.tests_required_exact" class="mt-2" />
                    </div>
                </div>

                <div class="mb-3 actions mx-auto max-w-md mt-8" >
                  <jet-button type="submit" class="my-1" buttonType="primary">Start</jet-button>
                </div>
              </form>
            </div>
            

        </transition-group>

    </div>
    <!-- end -->
    <footer class="section py-3 opacity-50">
      <div class="mx-auto text-xs text-center">&copy; 2023 FIRST CHOICE DIAGNOSTIC CO.</div>
    </footer>
  </div>


  
</template>

<script>

// ES6 Modules or TypeScript

// CommonJS
import JetInput from '@/components/FrontEnd/Input.vue';
import JetLabel from '@/components/FrontEnd/Label.vue';
import JetButton from '@/components/FrontEnd/Button.vue';
import JetInputError from '@/components/FrontEnd/InputError.vue'

import Header from './components/Header';
import Editor from './components/Editor';
import Notes from './components/Notes';

import { Form, Errors } from 'vform';

import Swal from 'sweetalert2';
// import CryptoJS from 'crypto-js';

// window.onbeforeunload = function(){
//   // return true;
//   Swal.fire({
//             title: 'Are you sure you want to finish this test?',
//             html: 'By clicking "Yes", all data will be cleared and you will be redirected to the Setup Random Testing page.',
//             showDenyButton: true,
//             icon: 'question',
//             showCancelButton: false,
//             confirmButtonText: 'Yes',
//             denyButtonText: 'No',
//         }).then((result) => {

//           if(result.value){
//             return true
//           }
//           else{
//             return false
//           }
//         });
//         return false;
// }
   

export default {
  name: 'App',
  components: {
    Header,
    Editor,
    Notes,
    JetInput,
    JetLabel,
    JetButton,
    JetInputError,
    Form,
    Errors,
    Swal,
    // CryptoJS

  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);

    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    if(params.get("debug") && params.get("debug") == 'true'){
      this.debug = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
  mounted() {
    window.addEventListener('offline', () => {
      this.$store.commit('updateIsOffline', true);
    });
    window.addEventListener('online', () => {
      this.$store.commit('updateIsOffline', false);

      // sync up a user's data with an external api
      this.syncUserData();
    });

  
    setTimeout(function(){
      this.loaded = true;
    }.bind(this), 500);



    
  },
  beforeUnmount() {
    this.$store.state.dispatch('destroyEditor');
  },
  props: {
    
  }, 
  data(){
    return {
      tests_required_options:[
        {
          key: 'percentage',
          label: 'Percentage'
        },
        {
          key: 'exact',
          label: 'Exact number'
        }
      ],
      form: new Form({
        total_population: '',
        tests_required_type: '',
        tests_required: null, // actual numbers
        tests_required_exact: null,
        tests_required_percentage: null,
      }),
      loaded: false,
      debug: false
    }
  },
  beforeRouteLeave (to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmStayInDirtyForm()){
      next(false)
    } else {
      // Navigate to next view
      next()
    }
  },
  methods: {
    getRandomIntInclusive(min, max) {
          const randomBuffer = new Uint32Array(1);
    
          var crypto = window.crypto || window.msCrypto;
    
          crypto.getRandomValues(randomBuffer);
    
          let randomNumber = randomBuffer[0] / (0xffffffff + 1);
    
          min = Math.ceil(min);
          max = Math.floor(max);
          return Math.floor(randomNumber * (max - min + 1)) + min;
      },
    confirmLeave() {
      return window.confirm('Do you really want to leave? you have unsaved changes!')
    },

    confirmStayInDirtyForm() {
      return this.isOffline && !this.confirmLeave()
    },

    beforeWindowUnload(e) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }   
    },
    
    syncUserData() {
      if (this.isOffline) {
        return;
      }
      // make my api request to an external server
    },
    pressButtonNext(){
      this.$store.dispatch('pressButtonNext');
    },
    pressButtonDraw(){
      this.$store.dispatch('pressButtonDraw');
    },
    cancelDraw(){

      Swal.fire({
            title: 'Are you sure you want to finish this test?',
            html: 'By clicking "Yes", all data will be cleared and you will be redirected to the Setup Random Testing page.',
            showDenyButton: true,
            icon: 'question',
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {

          if(result.value){
            this.$store.dispatch('cancelDraw');
            this.form.reset();
          }
        });
      
    },
    submitDraw(){
      // this.form.errors.clear();

      this.form.processing = true;
      this.form.errors.set({})

      //errors.set(field: string, message: string)


      if(this.form.tests_required_type == 'percentage'){
          this.form.tests_required = Math.ceil((parseInt(this.form.tests_required_percentage)  * parseInt(this.form.total_population)) / 100);
      }
      else{
          this.form.tests_required = this.form.tests_required_exact;
      }
      
      if(this.form.total_population == ''){
        this.form.errors.set('total_population', 'This field is required.');
      }

      if(this.form.tests_required_type == ''){
        this.form.errors.set('tests_required_type', 'This field is required.');
      }
      else{

        if(parseInt(this.form.tests_required) <= 0){
          this.form.errors.set('tests_required_percentage', 'Tests required should be atleast one.');
        }
        else if(parseInt(this.form.tests_required_exact) == parseInt(this.form.total_population) && this.form.tests_required_type != 'percentage'){
          this.form.errors.set('tests_required_percentage', 'Tests required should not be the same as the total population.');
        }
        else if(this.form.tests_required_type == 'exact'){
          if(this.form.tests_required_exact == '' || !this.form.tests_required_exact){
            this.form.errors.set('tests_required_exact', 'This field is required.');
          }
          else{

            if(this.form.total_population != ''){
              
              if(this.form.tests_required_exact > 0 && parseInt(this.form.tests_required_exact) < parseInt(this.form.total_population)){
                // valid
              }
              else{
                this.form.errors.set('tests_required_percentage', 'Value should be between 1 to '+(this.form.total_population-1)+'.');
              }
            }
          }
        }
        else if(this.form.tests_required_type == 'percentage'){
          if(this.form.tests_required_percentage == '' || !this.form.tests_required_percentage){
            this.form.errors.set('tests_required_percentage', 'This field is required.');
          }
          else{
            // 1-99 only
            if(this.form.tests_required_percentage > 0 && this.form.tests_required_percentage < 100){
              // valid
            }
            else{
              this.form.errors.set('tests_required_percentage', 'Value should be between 1 to '+(this.form.total_population-1)+'.');
            }
          }
        }
      }

      if(Object.keys(this.form.errors.errors).length == 0){
        // validation ok

        // this.form.tests_required = this.form.tests_required_exact;
        // if(this.form.tests_required_type == 'percentage'){
        //     this.form.tests_required = parseInt((this.form.tests_required_percentage / this.form.total_population) / 100)
        // }

        this.form.total_population = parseInt(this.form.total_population)
        if(this.form.tests_required_type == 'percentage'){
          this.form.tests_required_percentage = parseInt(this.form.tests_required_percentage)
        }
        else{
          this.form.tests_required_exact = parseInt(this.form.tests_required_exact)
        }
        this.$store.dispatch('addNewDraw', this.form);
      }

      this.form.processing = false;
      return false;
    }
  },
  computed: {
    activeNote() {
      return this.$store.state.activeNote;
    },
    isOffline() {
      return this.$store.state.isOffline;
    },
    draw(){
      return this.$store.state.draw;
    },
    totalFromPercentage(){
      if(this.form.tests_required_type == 'percentage'){
          if(this.form.tests_required_percentage && this.form.total_population){
            return Math.ceil((parseInt(this.form.tests_required_percentage)  * parseInt(this.form.total_population)) / 100);
          }
      }

      return null;
    },
    allWinnersDrawed(){
      if(this.draw && this.draw.current_index){
        var last_index = this.draw.results.length - 1;
        //if(this.draw.current_index < last_index){
          var has_yes = false;
          for(var x = this.draw.current_index; x<= last_index; x++){
            if(this.draw.results[x] == true){
              has_yes = true;
            }
          }
          
          if(!has_yes){
            return true;
          }

        //}
      }
      return false;
    }
  }
}
</script>
<style lang="postcss">
  button.save-note {
    @apply bg-none border border-gray-900 rounded py-1 px-4 mr-4 mt-3 hover:bg-gray-900 hover:text-white;
  }
  button.add-note {
    @apply flex items-center justify-center h-6 w-6 ml-1 rounded hover:bg-gray-300;
  }

  button.swal2-confirm.swal2-styled{
    @apply  px-10 shadow-sm text-lg rounded-lg  focus:outline-none uppercase hover:opacity-50  py-2.5  border bg-customRed text-white border-customRed my-1;
  }

  button.swal2-deny.swal2-styled{
    @apply  px-10 shadow-sm text-lg rounded-lg  focus:outline-none uppercase hover:opacity-50  py-2.5  border border-customRed bg-transparent text-customRed bg-white;
    border: 1px solid #cf2e2e;
  }
  .swal2-container .swal2-title{
    @apply text-2xl;
  }
  .swal2-container div.swal2-html-container#swal2-html-container{
    @apply text-base;
  }
</style>