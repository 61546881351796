<template>
    <div class="bg-transparent border-0 p-0 m-0 flex">
        <span v-if="prepend" class="border-0 border-b-2 border-b text-xl py-5" v-html="prepend" :class="[is_focused ? 'border-customYellow': 'border-white']"></span>
        <input :name="name" :type="type" class="block w-full border rounded border-gray-200 bg-transparents px-3 py-5" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" ref="input" :placeholder="placeholder" @focus="is_focused=true" @blur="is_focused=false">
    </div>
</template>

<script>
    import { defineComponent } from 'vue'

    export default defineComponent({
        props: ['modelValue','prepend', 'placeholder', 'type', 'name'],

        emits: ['update:modelValue'],
    
        methods: {
            focus() {
                this.$refs.input.focus()
            }
        },
        data(){
            return{
                is_focused: false
            }
        }
    })
</script>
