import { createApp } from 'vue';
import App from './App.vue';
import './app.css';
import './registerServiceWorker';

import { createStore } from 'vuex';

const helperMethods = {
    getRandomIntInclusive(min, max) {
        // based from https://stackoverflow.com/questions/18230217/javascript-generate-a-random-number-within-a-range-using-crypto-getrandomvalues
        const randomBuffer = new Uint32Array(1);
    
        var crypto = window.crypto || window.msCrypto;
    
        crypto.getRandomValues(randomBuffer);
    
        let randomNumber = randomBuffer[0] / (0xffffffff + 1);
    
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(randomNumber * (max - min + 1)) + min;
    }
};


const store = createStore({
    state() {
        return {
            editor: null,
            database: null,
            notes: [],
            activeNote: {},
            draw:{
                total_population: null,
                tests_required_type: '',
                tests_required: '', // actual numbers
                tests_required_exact: '',
                tests_required_percentage: '',
                results:[],
                created: null,
                current_index: 0,
                current_index_result_shown: false,
            },
            isOffline: !navigator.onLine
        }
    },
    mutations: {
        updateEditor(state, editor) {
            state.editor = editor
        },
        updateDatabase(state, database) {
            state.database = database
        },
        updateNotes(state, notes) {
            state.notes = notes
        },
        updateActiveNote(state, note) {
            state.activeNote = note
        },
        updateIsOffline(state, isOffline) {
            state.isOffline = isOffline
        },
        updateDraws(state, draw) {
            state.draw = draw
        },
        updateActiveDraw(state, draw) {
            state.activeDraw = draw
        },
    },
    actions: {
        init({ dispatch }) {
            dispatch('initDatabase').then(() => {
                dispatch('initDraws');
                // dispatch('initNotes');
            }).catch((e) => {

            })
        },
        initDatabase({ commit }) {
            return new Promise((resolve, reject) => {
                // initialize the database
                let db = window.indexedDB.open("draws", 2);

                db.onerror = e => {
                    reject('Error opening the database.');
                };

                db.onsuccess = e => {
                    commit('updateDatabase', e.target.result);
                    resolve('Test');
                };

                db.onupgradeneeded = e => {
                    if (e.oldVersion === 1) {
                        e.target.result.deleteObjectStore("draws");
                    }
                    e.target.result.createObjectStore("draws", { keyPath: "created" });
                };
            });
        },
        initNotes({ commit, state }) {
            // initialize the notes array
            state.database.transaction('notes')
                .objectStore('notes')
                .getAll()
                .onsuccess = e => {
                    commit('updateNotes', e.target.result);
                };
        },
        saveNote({ commit, state }) {
            let noteStore = state.database.transaction('notes', 'readwrite')
                .objectStore('notes');
            let noteRequest = noteStore.get(state.activeNote.created);

            noteRequest.onerror = e => {
                console.error('Error saving the note in the database.');
            };

            noteRequest.onsuccess = e => {
                let note = e.target.result;
                note.content = state.editor.getHTML();

                let updateRequest = noteStore.put(note);

                updateRequest.onerror = e => {
                    console.error('Error storing the updated note in the database.');
                };

                updateRequest.onsuccess = e => {
                    let notes = state.notes;
                    let noteIndex = notes.findIndex(n => n.created === note.created);
                    notes[noteIndex] = note;

                    commit('updateNotes', notes);
                };
            };
        },
        addNewNote({ commit, state }) {
            let transaction = state.database.transaction('notes', 'readwrite');

            let now = new Date();
            let note = {
                content: '',
                created: now.getTime()
            };

            // add that same note to the sidebar
            let notes = state.notes;
            notes.unshift(note);
            commit('updateNotes', notes);

            // set the activeNote as the new note
            commit('updateActiveNote', note);

            transaction.objectStore('notes').add(note);
        },
        destroyEditor({ commit, state }) {
            state.editor.destroy();
            commit('updateEditor', null);
        },

        // draws start
        initDraws({ commit, state }) {
            // initialize the notes array
            state.database.transaction('draws')
                .objectStore('draws')
                .getAll()
                .onsuccess = e => {
                    if(e.target.result.length > 0){
                        commit('updateDraws', e.target.result[0]);
                    }
                };
        },
        pressButtonNext({commit, state}){
            let transaction = state.database.transaction('draws', 'readwrite');
        
            var draw = JSON.parse(JSON.stringify(state.draw));
            draw.current_index_result_shown = false;
            draw.current_index = draw.current_index + 1;

            transaction.objectStore('draws').put(draw, state.created);

            commit('updateDraws', draw);
        },
        pressButtonDraw({commit, state}){
            let transaction = state.database.transaction('draws', 'readwrite');
        
            var draw = JSON.parse(JSON.stringify(state.draw));
            
            draw.current_index_result_shown = true;

            transaction.objectStore('draws').put(draw, state.created);
            // transaction.objectStore('draws').clear();
            commit('updateDraws', draw);
        },
        cancelDraw({commit, state}){
            let transaction = state.database.transaction('draws', 'readwrite');
            transaction.objectStore('draws').clear();
            commit('updateDraws', {
                total_population: null,
                tests_required_type: '',
                tests_required: '', // actual numbers
                tests_required_exact: '',
                tests_required_percentage: '',
                results:[],
                created: null,
                current_index: 0,
                current_index_result_shown: false,
            });
        },
        addNewDraw({ commit, state }, data) {
            let transaction = state.database.transaction('draws', 'readwrite');

            // var tests_required = data.tests_required_exact;
            // if(data.tests_required_type == 'percentage'){
            //     // tests_required = parseInt((data.tests_required_percentage / data.total_population) * 100)
            //     // tests_required = parseInt(data.total_population / data.tests_required_percentage);
            //     tests_required = Math.round((data.tests_required_percentage  * data.total_population) / 100);
            // }
            // else{
            //     data.tests_required_exact = data.tests_required_exact;
            // }

            var winners = [];

            // process winners
            for(var x = 0; x < data.tests_required; x++){
                // var winner = Math.floor(Math.random() * (parseInt(data.total_population)));
                var winner = helperMethods.getRandomIntInclusive(0,parseInt(data.total_population)-1);
                
                while(winners.includes(winner)){
                    // winner = Math.floor(Math.random() * (parseInt(data.total_population)));
                    var winner = helperMethods.getRandomIntInclusive(0,parseInt(data.total_population)-1);
                }
                winners.push(winner);
            }

            var results = [];

            for(var y = 0; y < parseInt(data.total_population); y++){
                if(winners.includes(y)){
                    results.push(true);
                }
                else{
                    results.push(false);
                }
            }

            let now = new Date();
            let draw = {
                total_population:   parseInt(data.total_population),
                tests_required_type: data.tests_required_type,
                tests_required: data.tests_required, // actual numbers
                tests_required_exact:       data.tests_required_exact,
                tests_required_percentage:  data.tests_required_percentage,
                results: results,
                current_index: 0,
                current_index_result_shown: false,
                created: now.getTime()
            };



            const getAllKeysRequest = transaction.objectStore('draws').getAllKeys();

            getAllKeysRequest.onsuccess = () => {
                var array_values = getAllKeysRequest.result;
                if(array_values.length > 0){
                    // deletes the draw
                    transaction.objectStore('draws').delete(array_values[0]);
                }
                
                transaction.objectStore('draws').put(draw);
            };

            commit('updateDraws', draw);

            
        },
        
        // draws end
    }
    
})

const app = createApp(App)

app.use(store)

app.mount('#app')

store.dispatch('init')