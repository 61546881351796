<template>
    <button :type="type" :class="'w-full px-4  shadow-sm text-lg rounded-lg  focus:outline-none uppercase hover:opacity-50  py-4 '+button_class">
        <slot></slot>
    </button>
</template>

<script>
    import { defineComponent } from 'vue'

    export default defineComponent({
        props: {
            type: {
                type: String,
                default: 'submit',
            },
            buttonType: {
                type: String,
                default: 'primary',
            },
        },
        computed: {
            button_class: function(){
                if(this.buttonType == 'primary'){
                    return ' border bg-customRed text-white border-customRed button-with-svg';
                }
                else{
                    return ' border border-customRed bg-transparent text-customRed bg-white ';
                }
            }
        }
    })
</script>
